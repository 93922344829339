import React, { useState, useEffect } from 'react';
import { Button, message, Spin } from 'antd';
import { AccountInfo, GetAccountInfoResponse, UserTier, cancelSubscription, getAccountInfo } from '../api/services/User';

const SubscriptionContent: React.FC = () => {
    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchAccountInfo = async () => {
        try {
            const response: GetAccountInfoResponse = await getAccountInfo.call();
            setAccountInfo(response.accountInfo);
            setLoading(false);
        } catch (error) {
            message.error('Failed to load account information');
            setLoading(false);
        }
    };

    const handleCancelSubscription = async () => {
        try {
            await cancelSubscription.call();
            message.success('Subscription canceled successfully');
            fetchAccountInfo(); // Refetch the account info to update UI
        } catch (error) {
            message.error('Failed to cancel subscription');
        }
    };

    useEffect(() => {
        fetchAccountInfo();
    }, []);

    if (loading) {
        return <Spin tip="Loading account info..." />;
    }

    return (
        <div>
            <h2>Subscription Details</h2>
            {accountInfo && (
                <>
                    <p><strong>Tier: </strong>{accountInfo.tier}</p>
                    {accountInfo.tier !== UserTier.FREE_TIER && (
                        <Button
                            type="primary"
                            danger
                            onClick={handleCancelSubscription}
                        >
                            Cancel Subscription
                        </Button>
                    )}
                </>
            )}
        </div>
    );
};

export default SubscriptionContent;