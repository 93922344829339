import React, { useState, useEffect } from 'react';
import { List, Typography, Layout, message, Button, Divider, Card, Row } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { Prompt, PromptGroupWithStats, PromptSource, PromptStatus, PromptVisibility, PromptWithStats, SortOrder, listPromptGroups, listPublicPromptsWithStats, upsertPrompt } from '../api/services/User';
import PromptEditorModal from './PromptEditorModal';  // Assuming the modal is in the same directory

const { Content, Sider } = Layout;
const { Text, Title, Paragraph } = Typography;

const PromptExplorer: React.FC = () => {
    const [promptGroups, setPromptGroups] = useState<PromptGroupWithStats[]>([]);
    const [prompts, setPrompts] = useState<PromptWithStats[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
    const [loadingGroups, setLoadingGroups] = useState(false);
    const [loadingPrompts, setLoadingPrompts] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);

    // Fetch prompt groups on component mount
    useEffect(() => {
        const fetchPromptGroups = async () => {
            setLoadingGroups(true);
            try {
                const response = await listPromptGroups.call();
                setPromptGroups(response.promptGroups);
            } catch (error) {
                message.error('Failed to load prompt groups.');
            } finally {
                setLoadingGroups(false);
            }
        };

        const fetchLatestPrompts = async () => {
            setLoadingPrompts(true);
            try {
                const response = await listPublicPromptsWithStats.call({ sortOrder: SortOrder.CREATION_DATE_SORT_ORDER });
                setPrompts(response.prompts);  // Set latest prompts
            } catch (error) {
                message.error('Failed to load latest prompts.');
            } finally {
                setLoadingPrompts(false);
            }
        };

        fetchPromptGroups();
        fetchLatestPrompts();  // Fetch the latest prompts initially
    }, []);

    // Fetch prompts for a selected group
    const fetchPromptsForGroup = async (group: string) => {
        setLoadingPrompts(true);
        try {
            const response = await listPublicPromptsWithStats.call({ group: group, sortOrder: SortOrder.POPULARITY_SORT_ORDER });
            setPrompts(response.prompts);
        } catch (error) {
            message.error('Failed to load prompts.');
        } finally {
            setLoadingPrompts(false);
        }
    };

    // Handle group selection
    const handleGroupClick = (group: string) => {
        setSelectedGroup(group);
        fetchPromptsForGroup(group);
    };

    // Handle prompt copy and open modal for editing
    const handleCopyPrompt = (promptWithStats: PromptWithStats) => {
        let prompt: Prompt = {
            description: promptWithStats.description,
            group: promptWithStats.group,
            title: promptWithStats.title,
            id: promptWithStats.id,
            promptSource: PromptSource.USER_GENERATED,
            status: PromptStatus.ACTIVE_PROMPT,
            visibility: PromptVisibility.PUBLIC,
        }
        setSelectedPrompt(prompt);
        setModalVisible(true);
    };

    // Close modal
    const handleCancel = () => {
        setModalVisible(false);
        setSelectedPrompt(null);
    };

    // Save the prompt
    const handleSave = async (values: any) => {
        const prompt: Prompt = {
            ...values,
            inspiredByPromptId: selectedPrompt?.id,
            status: PromptStatus.ACTIVE_PROMPT,
        };
        await upsertPrompt.call(prompt);
        message.success('Prompt saved');
        setModalVisible(false);
        setSelectedPrompt(null);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider width={300} style={{ backgroundColor: '#f0f2f5', padding: '20px' }}>
                <Title level={4}>Groups</Title>
                <List
                    loading={loadingGroups}
                    dataSource={promptGroups}
                    renderItem={(group) => (
                        <List.Item onClick={() => handleGroupClick(group.groupName)} style={{ cursor: 'pointer' }}>
                            {group.groupName ?? "Unspecified"} ({group.promptCount})
                        </List.Item>
                    )}
                />
            </Sider>

            <Content style={{ padding: '20px', backgroundColor: '#fff' }}>
                <Title level={4}>
                    {selectedGroup ? `Popular prompts in ${selectedGroup}` : 'Latest Prompt Additions'}
                </Title>
                <List
                    loading={loadingPrompts}
                    dataSource={prompts}
                    renderItem={(prompt) => (
                        <Card
                            style={{ width: 800, marginTop: '40px', border: '1px solid #f0f0f0' }}
                            bodyStyle={{ padding: '16px' }}
                        >
                            {/* Header row */}
                            <Row justify="space-between" style={{ backgroundColor: '#f0f0f0', padding: '8px' }}>
                                <Title level={5} style={{ margin: 0 }}>{prompt.title}</Title>
                                <Button
                                    type="link"
                                    icon={<CopyOutlined />}
                                    onClick={() => handleCopyPrompt(prompt)}
                                />
                            </Row>

                            {/* Description */}
                            <Paragraph style={{ padding: "10px" }} ellipsis={{ rows: 4, expandable: true, symbol: '...' }}>
                                {prompt.description}
                            </Paragraph>

                            <Divider />

                            {/* Footer row */}
                            <Row justify="space-between" style={{ marginTop: '8px' }}>
                                <Text>Usage: {prompt.usageCount}</Text>
                                <Text>Copies: {prompt.copyCount}</Text>
                                <Text>Contributed by: {prompt.creatorUserDisplayName}</Text>
                            </Row>
                        </Card>
                    )}
                />

                {/* Prompt Editor Modal */}
                {selectedPrompt && (
                    <PromptEditorModal
                        modalVisible={modalVisible}
                        onCancel={handleCancel}
                        onSave={handleSave}
                        groupOptions={promptGroups.map(group => group.groupName)}
                        selectedPrompt={selectedPrompt}
                    />
                )}
            </Content>
        </Layout>
    );
};

export default PromptExplorer;