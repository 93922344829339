import React, { useEffect } from 'react';
import { Modal, Form, Input, AutoComplete, Switch } from 'antd';
import { Prompt, PromptVisibility } from '../api/services/User';

interface PromptEditorModalProps {
    modalVisible: boolean;
    onCancel: () => void;
    onSave: (values: any) => void;
    selectedPrompt?: Prompt | null;
    groupOptions: string[]; // List of available groups for autocomplete
}

const PromptEditorModal: React.FC<PromptEditorModalProps> = ({
    modalVisible,
    onCancel,
    onSave,
    selectedPrompt,
    groupOptions
}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (selectedPrompt) {
            form.setFieldsValue({
                ...selectedPrompt,
                visibility: selectedPrompt.visibility === PromptVisibility.PRIVATE, // Convert enum to boolean
            });
        } else {
            form.resetFields();
        }
    }, [selectedPrompt, form]);

    const handleSave = (values: any) => {
        const promptData: Prompt = {
            ...values,
            visibility: values.visibility ? PromptVisibility.PRIVATE : PromptVisibility.PUBLIC, // Convert boolean back to enum
        };
        onSave(promptData);
    };

    return (
        <Modal
            visible={modalVisible}
            onCancel={onCancel}
            onOk={() => form.submit()}
            title={selectedPrompt ? 'Edit Prompt' : 'Add Prompt'}
            width={600}
        >
            <Form
                form={form}
                onFinish={handleSave}
                layout="vertical"
            >
                <Form.Item name="title" label="Title" rules={[{ required: true }]}>
                    <Input style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                    <Input.TextArea rows={5} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name="group" label="Group" rules={[{ required: true }]}>
                    <AutoComplete
                        options={groupOptions.map(option => ({ value: option }))}
                        style={{ width: '100%' }}
                        placeholder="Type to search or add new"
                    >
                        <Input />
                    </AutoComplete>
                </Form.Item>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <span style={{ marginRight: 8 }}>Make Private</span>
                    <Form.Item
                        name="visibility"
                        valuePropName="checked"
                        initialValue={false} // Default to false if not set
                        style={{ marginLeft: 10, flexGrow: 1 }}
                    >
                        <Switch />
                    </Form.Item>
                </div>
            </Form>
        </Modal>
    );
};

export default PromptEditorModal;