import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import AccountContent from './AccountContent';
import LocalStorageManager from '../common/LocalStorageManager';
import PromptsContent from './PromptsContent';
import SubscriptionContent from './SubscriptionContent'; // Import the new SubscriptionContent component
import LogoName from '../assets/LogoName.png'; // Import the logo image
import PromptExplorer from './PromptsExplorer';

const { Sider, Content } = Layout;

const Home: React.FC = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('account');

    const handleMenuClick = (e: any) => {
        setSelectedMenuItem(e.key);
    };

    const signout = () => {
        LocalStorageManager.clear();
        window.location.href = "https://rewrite.ly";
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider style={{ backgroundColor: '#2C3E50' }}>
                <div style={{ padding: '24px', textAlign: 'center' }}>
                    <img src={LogoName} alt="Logo" style={{ maxWidth: '100%' }} />
                </div>

                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[selectedMenuItem]}
                    onClick={handleMenuClick}
                    style={{ marginTop: '1em', flexGrow: 1, backgroundColor: '#2C3E50' }}
                >
                    <Menu.Item key="account">Account</Menu.Item>
                    <Menu.Item key="My Prompts">My Prompts</Menu.Item>
                    <Menu.Item key="Prompt Library">Prompt Library</Menu.Item>
                    <Menu.Item key="subscription">Subscription</Menu.Item> {/* Add Subscription menu */}
                </Menu>

                <div style={{ marginTop: 'auto', paddingBottom: '24px', backgroundColor: '#2C3E50' }}>
                    <Menu
                        theme="dark"
                        mode="inline"
                        style={{ backgroundColor: '#2C3E50' }}
                        selectedKeys={[]}
                    >
                        <Menu.Item key="signout" onClick={signout}>
                            Sign Out
                        </Menu.Item>
                    </Menu>
                </div>
            </Sider>

            <Layout>
                <Content style={{ padding: '24px', background: '#fff' }}>
                    {selectedMenuItem === 'account' && <AccountContent />}
                    {selectedMenuItem === 'My Prompts' && <PromptsContent />}
                    {selectedMenuItem === 'Prompt Library' && <PromptExplorer />}
                    {selectedMenuItem === 'subscription' && <SubscriptionContent />} {/* Add SubscriptionContent */}
                </Content>
            </Layout>
        </Layout>
    );
};

export default Home;