import React, { useState, useEffect } from 'react';
import { Tabs, Tree, Button, Modal, message } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Prompt, PromptSource, PromptStatus, listPrompts, upsertPrompt } from '../api/services/User';
import PromptEditorModal from './PromptEditorModal';

const { TabPane } = Tabs;

interface TreeNode {
    title: string;
    key: string | number;
    children?: TreeNode[];
    type: string;
    prompt: Prompt | undefined;
}

const PromptsContent: React.FC = () => {
    const [prompts, setPrompts] = useState<Prompt[]>([]);
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | null>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [groupOptions, setGroupOptions] = useState<string[]>([]);

    useEffect(() => {
        fetchPrompts();
    }, []);

    const fetchPrompts = async () => {
        try {
            const response = await listPrompts.call();
            setPrompts(response.prompts);
            const groups = Array.from(new Set(response.prompts.map(prompt => prompt.group).filter(Boolean)));
            setGroupOptions(groups);
        } catch (error) {
            message.error('Failed to load prompts');
        }
    };

    const handleAddPrompt = () => {
        setSelectedPrompt(null);
        setModalVisible(true);
    };

    const handleEditPrompt = (prompt: Prompt) => {
        setSelectedPrompt(prompt);
        setModalVisible(true);
    };

    const handleDeletePrompt = async (prompt: Prompt) => {
        let updatedPrompt: Prompt = { ...prompt, status: PromptStatus.DELETED_PROMPT };
        await upsertPrompt.call(updatedPrompt);
        message.success('Prompt deleted');
        fetchPrompts();
    };

    const handleSavePrompt = async (values: any) => {
        const prompt = {
            ...selectedPrompt,
            ...values,
            status: PromptStatus.ACTIVE_PROMPT,
        };
        await upsertPrompt.call(prompt);
        message.success('Prompt saved');
        setModalVisible(false);
        fetchPrompts();
    };

    const renderPromptTree = (source: PromptSource) => {
        const filteredPrompts = prompts.filter(p => p.promptSource === source);
        const groupedPrompts = filteredPrompts.reduce((acc: Record<string, TreeNode[]>, prompt: Prompt) => {
            const groupKey = prompt.group || 'Ungrouped';
            if (!acc[groupKey]) {
                acc[groupKey] = [];
            }
            acc[groupKey].push({
                title: prompt.title,
                key: prompt.id!,
                type: "prompt",
                prompt: prompt
            });
            return acc;
        }, {});

        const treeData: TreeNode[] = Object.keys(groupedPrompts).map(group => ({
            title: group,
            key: group,
            children: groupedPrompts[group],
            type: "group",
            prompt: undefined
        }));

        return (
            <Tree
                treeData={treeData}
                titleRender={(node) => (
                    <span>
                        {node.title}
                        {node.key && node.type === "prompt" && node.prompt?.promptSource === PromptSource.USER_GENERATED && (
                            <>
                                <EditOutlined
                                    style={{ marginLeft: 8 }}
                                    onClick={() => {
                                        const prompt = filteredPrompts.find(p => p.id === node.key);
                                        if (prompt) handleEditPrompt(prompt);
                                    }}
                                />
                                <DeleteOutlined
                                    style={{ marginLeft: 8 }}
                                    onClick={() => {
                                        const prompt = filteredPrompts.find(p => p.id === node.key);
                                        if (prompt) {
                                            Modal.confirm({
                                                title: 'Confirm Deletion',
                                                content: `Are you sure you want to delete the prompt "${prompt.title}"?`,
                                                okText: 'Yes',
                                                cancelText: 'No',
                                                onOk: () => handleDeletePrompt(prompt),
                                            });
                                        }
                                    }}
                                />
                            </>
                        )}
                    </span>
                )}
            />
        );
    };

    return (
        <div>
            <Tabs defaultActiveKey="1">
                <TabPane tab="My Prompts" key="1">
                    <Button type="primary" onClick={handleAddPrompt} style={{ marginBottom: 16 }}>
                        Add Prompt
                    </Button>
                    {renderPromptTree(PromptSource.USER_GENERATED)}
                </TabPane>
                <TabPane tab="System Prompts" key="2">
                    {renderPromptTree(PromptSource.SYSTEM)}
                </TabPane>
            </Tabs>

            <PromptEditorModal
                modalVisible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onSave={handleSavePrompt}
                selectedPrompt={selectedPrompt}
                groupOptions={groupOptions}
            />
        </div>
    );
};

export default PromptsContent;